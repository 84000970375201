
<template>
  <div class="home">
    <el-row>
        <el-col :span="24">
            <!-- 查询 -->
        </el-col>
        <!-- 列表 -->
      <el-col :span="24"
      ><div class="grid-content bg-purple">
          <el-form
                  :inline='true'
                  :model='this.pageInfo.params'
                  class='demo-form-inline'
                  style='margin-left: 6px;height: 53px; '
          >
              <el-form-item label='登录名称：'>
                  <el-input

                          v-model='pageInfo.params.loginName'
                          placeholder='请输入登录名称'
                  ></el-input>
              </el-form-item>
              <el-form-item label='学历：'  >
                  <el-select v-model="pageInfo.params.codeId"  class="" placeholder="请选择学历">
                      <el-option

                              v-for="item in queryEducationList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label='期望薪资：' >
                  <el-select v-model="pageInfo.params.salaryExpectation" class="" placeholder="请选择期望薪资">
                      <el-option
                              v-for="item in querySalaryList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item>
                  <el-button type='primary' icon='el-icon-search' @click='query()'
                  >查询
                  </el-button
                  >
              </el-form-item>
              <el-form-item>
                  <el-button type='primary' icon='el-icon-refresh' @click='reset()'
                  >重置
                  </el-button
                  >
              </el-form-item>
              <el-form-item>
                  <el-button type='primary' icon='el-icon-connection' @click='relationshipBetween()'
                  >关系
                  </el-button
                  >
              </el-form-item>

          </el-form>
        <el-table
                :data="this.tableData"
                border
                style="width: 100%"
        >
          <el-table-column
                  type="index"
                  prop="sortOrder"
                  header-align="center"
                  align="center"
                  label="序号"
                  width="60"
                  :index="indexMethod"
          >
          </el-table-column>
          <el-table-column
                  prop="loginName"
                  header-align="center"
                  align="center"
                  label="登录名称"
                  width="170"
          >
          </el-table-column>
            <el-table-column
                    prop="realName"
                    header-align="center"
                    align="center"
                    label="真实姓名"
            >
            </el-table-column>
          <el-table-column
                  prop="codeId"
                  header-align="center"
                  align="center"
                  label="学历"
                  :formatter="codeId"
          >
          </el-table-column>
          <el-table-column
                  prop="salaryExpectation"
                  header-align="center"
                  align="center"
                  :formatter="salaryExpectation"
                  label="期望薪资"
          >
          </el-table-column>

          <el-table-column
                  prop="createDatetime"
                  header-align="center"
                  align="center"
                  label="创建时间"
                  width="160"
          >
          </el-table-column>
          <el-table-column
                  header-align='center'
                  align='center'
                  label='状态'>
            <template slot-scope='scope'>
              <el-switch
                      v-model='scope.row.isUse'
                      active-color='#13ce66'
                      active-value='Y'
                      inactive-value='N'
                      class='main-switch'
                      @change='derail(scope.row.id,scope.row.isUse)'
                      inactive-color='#ff4949'>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
                  label="操作"
                  header-align="center"
                  align="center"
                  width="280"
          >
            <template slot-scope="scope">
              <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-s-custom"
                      @click="handleEdit(scope.row.id)"
              >个人信息</el-button
              >
                <el-button @click="drawerDrawer(scope.row.id)"
                           type="primary"
                           size="mini"
                           icon="el-icon-tickets">
                    个人简历
                </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div></el-col
      >
    </el-row>
<!-- 个人简历查看表单 -->
      <el-drawer
              :show-close="true"
              title="个人简历"
              :visible.sync="drawer"
              :append-to-body="true"
              top='5vh'>
          <el-form :model="formResume"   ref="formName" label-width="100px">
            <el-form-item label='头像：'  class='form' prop='headPortraitPath'>
              <img style="width: 100px;height: 100px;margin-left: 19px;" :src='formResume.headPortraitPathHttpUrl' alt=''>
            </el-form-item>
            <br>
            <el-form-item label="真实姓名：" class="form" prop="realName">
              <span class="formSpan">
                  {{ formResume.realName }}
              </span>
            </el-form-item>
            <el-form-item label="性别：" class="form" prop="sex">
              <span class="formSpan">
                  {{ formResume.sex }}
              </span>
            </el-form-item>
            <el-form-item label="学历：" class="form" prop="codeId">
            <span class="formSpan">
                {{ formResume.codeId }}
            </span>
            </el-form-item>
            <el-form-item prop='birthDate'  class="form" label='出生日期：'>
            <span class="formSpan">
                {{ formResume.birthDate }}
            </span>
            </el-form-item>
<!--            <el-form-item label="期望薪资：" class="form inputDeep" prop="salaryExpectation" >-->
<!--            <span class="formSpan">-->
<!--                {{ formResume.salaryExpectation }}-->
<!--            </span>-->
<!--            </el-form-item>-->
            <el-form-item label="工作年限：" class="form inputDeep" prop="workingYearsName">
            <span
                class="formSpan"
            >
                {{ formResume.workingYearsName }}
            </span>
            </el-form-item>
            <br>
            <el-form-item label="综合能力：" class="form inputDeep" prop="comprehensiveAbility">
            <span
                class="formSpans"
            >
                {{ userInfoSupplementAdapter.comprehensiveAbility }}
            </span>
            </el-form-item>
            <br>
            <el-form-item label="自我评价：" class="form inputDeep" prop="personalAdvantage">
            <span
                class="formSpans"
            >
                {{ userInfoSupplementAdapter.personalAdvantage }}
            </span>
            </el-form-item>
<!--            工作经历-->
            <el-divider content-position="center"  style="margin: 43px 0;">工作经历</el-divider>
            <el-form style="border:1px solid #cccccc;margin-bottom: 10px;"  v-for="(item,index) in userWorkCompanyList" ref="formName" label-width="100px">
              <el-form-item label="公司名称：" class="form inputDeep" prop="companyName">
            <span
                class="formSpans"
            >
                {{ item.companyName }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="公司行业：" class="form inputDeep" prop="industry">
            <span
                class="formSpans"
            >
                {{ item.industry }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="职位名称：" class="form inputDeep" prop="positionName">
            <span
                class="formSpans"
            >
                {{ item.positionName }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="工作内容：" class="form inputDeep" prop="jobContent">
            <span
                class="formSpans"
            >
                {{ item.jobContent }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="入职时间：" class="form inputDeep" prop="startTime">
            <span
                class="formSpans"
            >
                {{ item.startTime }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="离职时间：" class="form inputDeep" prop="endTime">
            <span
                class="formSpans"
            >
                {{ item.endTime }}
            </span>
              </el-form-item>
            </el-form>
          <!--教育经历-->
            <el-divider content-position="center">教育经历</el-divider>
            <el-form style="border:1px solid #cccccc;margin-bottom: 10px;" v-for="(item,index) in userEducationalExperienceList"  ref="formName" label-width="100px">
              <el-form-item label="学校名称：" class="form inputDeep" prop="schoolName">
            <span
                class="formSpans"
            >
                {{ item.schoolName }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="专业：" class="form inputDeep" prop="major">
            <span
                class="formSpans"
            >
                {{ item.major }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="学历：" class="form inputDeep" prop="education">
            <span
                class="formSpans"
            >
                {{ item.education }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="入学时间：" class="form inputDeep" prop="startTime">
            <span
                class="formSpans"
            >
                {{ item.startTime }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="毕业时间：" class="form inputDeep" prop="endTime">
            <span
                class="formSpans"
            >
                {{ item.endTime }}
            </span>
              </el-form-item>
              <br>

            </el-form>
<!--           项目经验-->
            <el-divider content-position="center">项目经验</el-divider>
            <el-form style="border:1px solid #cccccc;margin-bottom: 10px;" v-for="(item,index) in userWorkExperienceList"  ref="formName" label-width="100px">
              <el-form-item label="项目名称：" class="form inputDeep" prop="projectName">
            <span
                class="formSpans"
            >
                {{ item.projectName }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="项目链接：" class="form inputDeep" prop="projectLink">
            <span
                class="formSpans"
            >
                {{ item.projectLink }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="担任角色：" class="form inputDeep" prop="playRole">
            <span
                class="formSpans"
            >
                {{ item.playRole }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="开始时间：" class="form inputDeep" prop="startTime">
            <span
                class="formSpans"
            >
                {{ item.startTime }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="结束时间：" class="form inputDeep" prop="endTime">
            <span
                class="formSpans"
            >
                {{ item.endTime }}
            </span>
              </el-form-item>
              <br>
              <el-form-item label="项目描述：" class="form inputDeep" prop="projectDesc">
            <span
                class="formSpans"
            >
                {{ item.projectDesc }}
            </span>
              </el-form-item>
              <br>

            </el-form>
            <el-form-item  style='display: flex;flex-direction: row;justify-content: center;margin: 10px 80px 0px 0px'>
                  <el-button type="primary" @click="drawer = false">关闭</el-button>
              </el-form-item>
          </el-form>

      </el-drawer>
<!-- 点击关系弹出的表单 -->
      <el-dialog
              top='3vh'
              width='100%'
              :show-close="true"
              :title="this.title"
              :visible.sync="dialogFormVisibleBetween"
              :append-to-body="true"
      >
          <template>
              <div>
                  <mindmap
                          v-model="data"
                          width="50%"
                          fitView="false"
                          showUndo="false"
                          download="false"
                          zoomable ="true"
                  ></mindmap>
              </div>
          </template>
      </el-dialog>
    <!-- 分页 -->
    <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageInfo.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="recordsTotal"
            style="margin-top: 10px"
    >
    </el-pagination>
    <div style="margin-top: 10px; height: 3px; width: 100%"></div>
    <!-- 表单 -->
    <el-dialog
            :show-close="true"
            :title="this.titlee"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            top='5vh'
            @close="close('formName')"
    >
      <el-form :model="form"  ref="formName" label-width="100px">
          <el-form-item label="登录名称：" class="form" prop="loginName">
              <span class="formSpan">
                  {{ form.loginName }}
              </span>
          </el-form-item>
          <el-form-item label="真实姓名：" class="form" prop="realName">
              <span class="formSpan">
                  {{ form.realName }}
              </span>
          </el-form-item>
          <el-form-item label="昵称：" class="form" prop="nickName">
              <span class="formSpan">
                  {{ form.nickName }}
              </span>
          </el-form-item>
          <el-form-item label="性别：" class="form" prop="sex">
              <span class="formSpan">
                  {{ form.sex }}
              </span>
          </el-form-item>
        <el-form-item label="学历：" class="form" prop="codeId">
            <span class="formSpan">
                {{ form.codeId }}
            </span>
        </el-form-item>
        <el-form-item prop='birthDate'  class="form" label='出生日期：'>
            <span class="formSpan">
                {{ form.birthDate }}
            </span>
        </el-form-item>
        <el-form-item label="期望薪资：" class="form inputDeep" prop="salaryExpectation" >
            <span class="formSpan">
                {{ form.salaryExpectation }}
            </span>
        </el-form-item>
        <el-form-item label="工作年限：" class="form inputDeep" prop="workingYearsName">
            <span
                    class="formSpan"
            >
                {{ form.workingYearsName }}
            </span>
        </el-form-item>
          <el-form-item label="绑定微信：" class="form inputDeep" prop="weixin">
            <span
                    class="formSpan"
            >
                {{ form.weixin }}
            </span>
          </el-form-item>
          <el-form-item prop="createDatetime" class="form" label="注册时间：">
              <span class="formSpan">
                  {{ form.createDatetime }}
              </span>
          </el-form-item>
        <el-form-item label='头像：'  class='form' prop='headPortraitPath'>
                <img style="width: 100px;height: 100px;margin-left: 19px;" :src='form.headPortraitPathHttpUrl' alt=''>
        </el-form-item>
        <el-form-item style='display: flex;flex-direction: row;justify-content: center;margin: 10px 80px 0px 0px'>
            <el-button type="primary" @click="dialogFormVisible = false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import mindmap from '@hellowuxin/mindmap';
  import {
    userInfoDetail,
    sysUserQueryList,
    queryEducationList,
    querySalaryList,
    disable,
    queryTreeList,
    resumeDetaiil
  } from '@/api/userInfo'
  export default {
    components: { mindmap },
    name: 'index',
    data() {
      return {
        eorkExperience:[],
        userInfoSupplement:false,
        innerDrawer:false,
        drawer:false,
        queryTreeList:[],
        imgList: [],
        imgRight:[],// 图片数据
        dialogImageUrls: '',
        dialogImage:'',
        title: null, // 表单弹窗标题
        titlee:null, //关系表单标题
        tableData: [], // 列表数据
        recordsTotal: null, // 列表总数
        dialogFormVisible: false, // 表单弹框快关
        dialogFormVisibleBetween:false,
        dialogVisible:false,
        querySalaryList:null, //薪资
        queryEducationList:null,
        queryWorkingYearsList:null,
        birthDate: null,
        disable:null,
        labelPosition: 1,
        isUse:0,
        folder:{
          folder:'userInfo'
        },
        page:1,
        pageInfo: {
          // 分页查询数据
          page: 1,
          pageSize: 10,
          params: {
            // 搜索数据
            loginName: null,
            codeId: null,
            sysRoleId: null
          }
        },
        form: {
          // 表单数据
          birthDate: null,
          codeId: null,
          codeValue:null,
          loginName: null,
          realName:null,
          nickName:null,
          password: null,
          salaryExpectation: null,
          jobs:null,
          sex: null,
          userDesc:null,
          id:null,
          weixin:null,
          workingYearsName:null
        },
        userInfoId:null,
        userInfoSupplementAdapter:{
          comprehensiveAbility:null,
          personalAdvantage:null
        },
        userWorkCompanyList:[],
        userEducationalExperienceList:[],
        userWorkExperienceList:[],
        formResume:{
          loginName:null,
          realName:null,
          nickName:null,
          sex:null,
          codeId:null,
          weixin:null,
          birthDate:null,
          salaryExpectation:null,
          workingYears:null,
          id:null,
          userInfoId:null,
          personalAdvantage:null,
          education:null,
          companyName:null,
        },
        data: [{
          "name":"蓝英科技",
          "children": [
          ]
        }],
        //思维导图
        direction: 'rtl',
        theme_value: '',
        options: {
          container: 'jsmind_container', // [必选] 容器的ID
          editable: false, // [可选] 是否启用编辑
          theme: 'primary', // [可选] 主题
          support_html : true,    // 是否支持节点里的HTML元素
          draggable: false,
          gps: false,
          fitView: false,
          showNodeAdd: false,
          keyboard: false,
          contextMenu: false,
          zoomable: false,
          showUndo: false,
          download: false,
          view:{
            engine: 'canvas',   // 思维导图各节点之间线条的绘制引擎
            hmargin:100,        // 思维导图距容器外框的最小水平距离
            vmargin:50,         // 思维导图距容器外框的最小垂直距离
            line_width:1,       // 思维导图线条的粗细
            line_color:'#C18AFB'   // 思维导图线条的颜色
          },
          menuOpts:{
            showMenu: true,
          },
        },
        isShow: true,
        isLoad: false,
      }
    },

    mounted() {
      this.getList();
      querySalaryList().then(res=>{
        this.querySalaryList = res
      })
      queryEducationList().then(res=>{
        this.queryEducationList = res
      })
      queryTreeList().then(res=>{
        this.queryTreeList = res;
        if(res !== []) {
          this.queryTreeListFor(this.queryTreeList);
        }
        this.data[0].children = this.queryTreeList;

      })
      console.log('this.data',this.data)

    },

    methods: {
      queryTreeListFor(res) {
        res.forEach(data => {
          data.name = data.loginName;
          data.children = data.childList
          if(data.children != null) {
            this.queryTreeListFor(data.children)
          } else {
            return this.queryTreeList
          }
        })
      },

      // 文件列表移除文件时的钩子
      handleRemove() {
        this.form.headPortraitPathHttpUrl = null
      },
      handleRemoveCode() {
        this.form.qrCodePathHttpUrl = null
        this.formResume.qrCodePathHttpUrl = null
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogFormVisible = true
        this.dialogVisible = true
      },
      handlePictureCardPreviewCode(file) {
        this.dialogImage = file.url
        this.dialogFormVisible = true
        this.dialogVisible = true
      },
      // 图片上传返回的数据
      handleAvatarSuccess(response) {
        this.form.headPortraitPath = response
        this.formResume.headPortraitPath = response
      },
      handleAvatarSuccessCode(response) {
        this.form.qrCodePath = response

      },
      indexMethod(index) {
        index = index + 1 + (this.page - 1) * this.pageInfo.pageSize
        return index
      },
      codeId(row){
        if(this.queryEducationList != null) {
          for (let i=0; i < this.queryEducationList.length; i++){
            if(this.queryEducationList[i].id === row.codeId) {
              return this.queryEducationList[i].value;
            }
          }
        }
      },
      //期望薪资
      salaryExpectation(row){
        if(this.querySalaryList != null) {
          for (let i=0; i < this.querySalaryList.length; i++){
            if(this.querySalaryList[i].id === row.salaryExpectation) {
              return this.querySalaryList[i].value;
            }
          }
        }
      },
      //微信绑定
      //清空表单
      formData() {
        this.form.birthDate = null
        this.form.password = null
        this.form.codeId = null
        this.form.tel = null
        this.form.userDesc = null
        this.form.id = null
        this.form.sysRoleId = null
        this.form.status = null
        this.dialogImageUrl = null
        this.dialogImage = null
      },
      formDataResume(){
        this.formResume.birthDate = null

      },
      // 获取列表内容
      getList() {
        this.loading = true
        sysUserQueryList(this.pageInfo).then(res => {
          this.recordsTotal = res.recordsTotal
          this.tableData = res.data
        })
      },
      query() {
        this.getList()
      },
      reset() {
        this.pageInfo.params.loginName = null
        this.pageInfo.params.codeId = null
        this.pageInfo.params.salaryExpectation = null
        this.pageInfo.params.sex = null
        this.getList()
      },
      //关系
      relationshipBetween(){
        this.title = "关系"
        this.dialogFormVisibleBetween = true
        this.getList()

      },
      //个人简历查看
      drawerDrawer(id){
        this.imgList = []
        resumeDetaiil(id).then(res => {
          this.formResume = res.userInfoAdapter
          this.userInfoSupplementAdapter = res.userInfoSupplementAdapter
          this.userWorkCompanyList = res.userWorkCompanyList
          this.userEducationalExperienceList = res.userEducationalExperienceList
          this.userWorkExperienceList = res.userWorkExperienceList
          //判断项目经验是否为空
          if(this.userWorkExperienceList != null){
            for (let i=0;i < this.userWorkExperienceList.length;i++){
              this.userWorkExperienceList = res.userWorkExperienceList
            }
          }else if(this.userWorkExperienceList == null){
            this.userWorkExperienceList = []
          }
          //判断教育经历是否为空
          if(this.userEducationalExperienceList != null){
            for (let i=0;i < this.userEducationalExperienceList.length;i++){
              this.userEducationalExperienceList = res.userEducationalExperienceList
            }
          }else if(this.userEducationalExperienceList == null){
            this.userEducationalExperienceList = []
          }
          //判断工作经历是否为空
          if(this.userWorkCompanyList != null){
            for (let i=0;i < this.userWorkCompanyList.length;i++){
              this.userWorkCompanyList = res.userWorkCompanyList
            }
          }else if(this.userWorkCompanyList == null){
            this.userWorkCompanyList = []
          }
          // 判断综合能力 自我评价是非为空
          if(this.userInfoSupplementAdapter == null){
            this.userInfoSupplementAdapter = []
          }
          this.imgList.push({
            'url': res.headPortraitPathHttpUrl
          })

          if(this.queryEducationList != null) {
            // console.log('111')
            for (let i=0; i < this.queryEducationList.length; i++){
              // console.log('111')
              if(this.queryEducationList[i].id === this.formResume.codeId) {
                this.formResume.codeId = this.queryEducationList[i].value;
              }
            }
          }
          if(this.querySalaryList != null) {
            for (let i=0; i < this.querySalaryList.length; i++){
              if(this.querySalaryList[i].id === this.formResume.salaryExpectation) {
                this.formResume.salaryExpectation = this.querySalaryList[i].value;
              }
            }
          }
          this.drawer = true
        })
      },

      //编辑
      handleEdit(id) {
        this.imgList = [];
        this.imgRight = [];
        this.titlee = '查看';
        userInfoDetail(id).then(res => {
          this.form = res
          this.form.id = res.id
          this.imgList.push({
            'url': res.headPortraitPathHttpUrl
          })
            if(this.queryEducationList != null) {
            for (let i=0; i < this.queryEducationList.length; i++){
              if(this.queryEducationList[i].id === this.form.codeId) {
                this.form.codeId = this.queryEducationList[i].value;
              }
            }
          }
          if(this.querySalaryList != null) {
            for (let i=0; i < this.querySalaryList.length; i++){
              if(this.querySalaryList[i].id === this.form.salaryExpectation) {
                this.form.salaryExpectation = this.querySalaryList[i].value;
              }
            }
          }
          if(this.form.weixin != null){
            this.form.weixin = "已绑定"
          }else {
            this.form.weixin = "未绑定"
          }

        })
        this.dialogFormVisible = true
      },
      // 取消
      formCancel(formName) {
        this.dialogFormVisible = false

      },
      //  分页
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      // 清除表单验证
      close(formName) {
        this.$refs[formName].resetFields()
      },
      derail(id, isUse) {
        let statusList = { id, isUse }
        disable(statusList).then(res => {
          this.getList()
        })
      },

    }
  }
</script>
<style lang='less' scoped>
/deep/.el-divider__text {
  font-size: 18px;
}
/deep/ .el-divider--horizontal{
  margin: 43px 0px;
  font-size: 15px;
}
.theTitle{
  margin: 25px;
  text-align: center;
}
    /deep/.el-drawer__header {
        align-items: center;
        color: #72767b;
        display: flex;
        margin-bottom: 0px !important;
        padding: 20px 20px 0;
    }
    .user_Educational_Experience_List{
        width: 400px;
        margin: auto;
        display: block;
    }
    /deep/ .el-drawer__body{
        padding: 20px !important;
    }
    .el-form{
        min-width: 100%;
    }
    /deep/ foreignObject{
        height: 19px;
        overflow: initial;
    }
    /deep/ div#mindmap svg g.depth_0.node>foreignObject div {
        color: #4b4b4b;
        margin-left: -1px;
    }
    /deep/ div#mindmap {
        font-size: 12px;
        height: 600px !important;
    }
    /deep/ div#mindmap svg foreignObject div {
         min-height: 0px;

    }
    /deep/ div#mindmap div.buttonList {
        display: none !important;
    }
    /deep/ div#mindmap div.buttonList.right-bottom {
        display: none !important;
    }
    /deep/ g.depth_0.node{
        transform: translate(-50px,0) scale(0.3) !important;
    }
   /deep/ .gButton{
        display: none !important;
    }
  .home {
    margin: auto;
  }
  .form {
    width: 45%;
    display: inline-block;
    margin: 10px 10px;
  }
  .formInput {
    width: 100%;
    margin-left: 16px;

  }
  .formInput input{
      border: 0 !important;
  }
  .el-switch__label--left {
    position: relative;
    left: 57px;
    color: #fff;
    z-index: -1111;
  }
  .el-switch__core {
    width: 50px !important;
  }
  .el-switch__label--right {
    position: relative;
    right: 57px;
    color: #fff;
    z-index: -1111;
  }
  .el-switch__label--right.is-active {
    z-index: 1111;
    color: #fff !important;
  }
  .el-switch__label--left.is-active {
    z-index: 1111;
    color: #9c9c9c !important;
  }

  .el-upload--picture-card{
      display: none;
  }
  .el-upload-list__item{
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 108px !important;
    height: 108px !important;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
  .el-upload-list__item-thumbnail{
    width: 104px !important;
    height: 104px !important;
  }
  .el-form-item{
      margin-bottom: 12px;
  }
  /deep/ .el-form--inline{
      height: 100% !important;
  }
  .el-form-item__content{
    height: 70%;
  }

  .el-form-item__content{
      line-height: 0px;
  }
  .el-form-item__content::after {
      clear: both;
      margin-left: 0;
  }
  .el-select .el-input.is-disabled .el-input__inner
  {
      cursor: not-allowed;
      background: #ffff;
      color: #424242c9;
  }
  .el-input.is-disabled .el-input__inner {
      background: #ffff;
      border-color: #E4E7ED;
      color: #424242c9;
      cursor: not-allowed;
  }
  .el-textarea.is-disabled .el-textarea__inner {
       background-color: #ffff;
      border-color: #E4E7ED;
      color: #424242c9;
      cursor: not-allowed;
  }
  .brain_content {
      /deep/ jmnodes.theme-clouds jmnode{background-color: #f0fadd;color:#333;}
      /deep/ jmnodes.theme-clouds jmnode:hover{background-color:#D6E3BD;}
      /deep/ jmnodes.theme-clouds jmnode.selected{background-color: #b6d47e;color:#333;}
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      .header_btn {
          height: 40px;
          line-height: 40px;
      }
      .js_mind {
          flex: 1;
      }
  }
  div#mindmap{
      height: 500px;
  }
.formSpan{
    display: inline-block;
    /*padding: 0 15px;*/
    margin-left: 16px;
    width: 100%;

}
.formSpans{
    /*display: block;*/
    margin-left: 16px;
    width: 300%;
    word-break:normal;
    display:block;
    overflow:hidden;
}
   /deep/ .el-dialog__body {
        padding: 5px 20px 30px 20px !important;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }


</style>
