import {get,post} from "../http/index";

// 列表分页
export const userInfoPage = (params) => post('/userInfo/page', params);

//只穿page的分页接口
export const sysUserQueryList = (page) => post('/sysUser/queryList/' + page);

// 删除
export const userInfoDelete = (params) => post('/userInfo/delete?id=' + params);

// 详情
export const userInfoDetail = (params) => get('/userInfo/detail/' + params);

//薪资
export const  querySalaryList = () => get('commonData/querySalaryList');
export const  queryEducationList = () => get('commonData/queryEducationList');
export const disable = (params) => post('/userInfo/save', params);

//Tree集合
export const queryTreeList  = () => get('/userInfo/queryTreeList');

//工作年限集合
export const queryWorkingYearsList = () => get('/commonData/queryWorkingYearsList');
//个人简历
export const resumeDetaiil = (params) => get('/resume/detail/' + params);
